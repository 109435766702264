import { School } from '../../../../types'
import './EducationEntry.css'

interface EducationEntryProps {
    school: School
  }
  
export const EducationEntry: React.FC<EducationEntryProps> = ({school}) => {
    return (<>
      <div className="icon"></div>
          <div className="date-content">
            <div className="date-outer">
              <span className="school-date">
                <span className="year">{school.year}</span>
              </span>
            </div>
          </div>
      <div className="school-content">
        <div className="school-name">{school.school}</div>
        <div className="school-degree">{school.degree}</div>
      </div>
      </>
    )
  }