import './JobEntry.css'

import { useState } from 'react';
import parse from 'html-react-parser';

import { Job } from "../../../../types";
import Skills from "../../../organisms/Skills/Skills";
import Certifications from "../../../organisms/Certifications/Certifications";

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

interface JobEntryProps {
    job: Job;
    extraDetailsElement?: JSX.Element;
}

export const JobEntry: React.FC<JobEntryProps> = ({ job, extraDetailsElement}) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <div className="icon"></div>
        <div className="date-content">
          <div className="date-outer">
            <span className="date">
              {/* <span className="month">{`${job.durationInYears} Year${
                job.durationInYears === "1" ? "" : "s"
              }`}</span> */}
              <span className="work-duration">{`${job.startDate} - ${job.endDate}`}</span>
              <span className="year">{job.startYear}</span>
            </span>
          </div>
        </div>
        <div className="job-content">
          <div className="title">
            <div className="title-company">{job.company}</div>
            <div className="title-jobrole">{job.title}</div>
          </div>
          <div className="description">
            <ul>
              {job.details != null &&
                job.details.map((detail: string, index: number) => {
                  return (
                    <li key={job.company + ",detail" + String(index)} className="job-details">
                      {parse(detail)}
                    </li>
                  );
                })}
              {extraDetailsElement != null &&
                <li>{extraDetailsElement}</li>
              }

            {job.extraDetails && 
                <li>
                <Button variant="info" className="extra-details-button" onClick={() => setOpen(!open)} aria-controls="collapse-text" aria-expanded={open}>More details</Button>
                <Collapse in={open}>
                    <ul className="extra-details">{job.extraDetails.map((detail: string, index: number) => {
                        return (<li key={`${job.title}_extradetails_${index}`}>{detail}</li>);
                    })}
                    </ul>
                </Collapse>
                </li>
            }
            </ul>
        </div>
       
        <div className="certs--skills--container">
            {job.certifications != null && job.certifications.length > 0 && <Certifications certifications={job.certifications} />}
            {job.newSkills != null && job.newSkills.length > 0 && <Skills skills={job.newSkills} isSkillsList={true} />}
            {job.newPatterns != null && job.newPatterns.length > 0 && <Skills skills={job.newPatterns} />}
        </div>
        </div>
      </>
    );
  };