import "./MissionStatement.css";

export const MissionStatement: React.FC = () => {
  return (
    // <div className="mission--statement m-5">
    //   Passionate, Driven, Concept-focused technologist eager to
    //   <div className="mission--statement--aboutme" />
    //   meaningful products that impact lives.
    // </div>
    <div className="mission--statement m-5">
      Driven and concept-focused 
      <div className="mission--statement--aboutme--v2" />
       with engaging and dynamic leadership style that ignites creativity in turning complex problems into impactful products.
      </div>
  );
}