import { CertificationType } from "./Components/organisms/Certifications/Certifications"
import { HobbyProject, Jobs, Personal, School, GlobalSkills, HardSkills, SoftSkills } from "./types"

export const welcome = "Thanks for checking out my site! Please reach out with any thoughts/question you might have for me!"

export const personal: Personal = {
    name: "Ethan Bowen",
    email: "ethan.a.bowen@gmail.com",
    whoAmI: "Passionate, driven, concept-focused technologist eager to create meaningful products that impact lives.",
    yearsExperience: 11,
    social: {
        linkedIn: "https://www.linkedin.com/in/ethan-bowen-89115250/",
        gitHub: "https://github.com/ethanabowen",
        resume: "../../..//Resume/Resume.pdf"
    }
}

export const schools: School[] = [
    {
        school: "Texas Tech University",
        degree: "Bachelor's of Science in Computer Science",
        year: 2012
    },
    {
        school: "University of Texas at Arlington",
        degree: "Master's of Science in Computer Science",
        year: 2016
    }
]

export const hardSkills: HardSkills = {
    analysis: {
        AWSAthena: "AWS Athena",
        AWSGlue: "AWS Glue",
        AWSQuickSight: "AWS QuickSight",
        AWSLakeFormation: "AWS Lake Formation",
        Tableau: "Tableau"
    },
    applicationIntegration: {
        AmazonEventBridge: "Amazon EventBridge",
        SNS: "SNS",
        SQS: "SQS",
        StepFunctions: "Step Functions"
    },
    cicd: {
        Expo: "Expo",
        Git: "Git",
        Gitlab: "Gitlab",
        Jenkins: "Jenkins",
        SonarQube: "SonarQube"
    },
    cloud: {
        AWS: "AWS",
        Azure: "Azure"
    },
    communication: {
        JMS: "JMS",
        RabbitMQ: "RabbitMQ",
        REST: "REST",
        SOAP: "SOAP",
        WebSphere: "WebSphere"
    },
    compute: {
        EC2: "EC2",
        Lambda: "Lambda"
    },
    containers: {
        ECS: "ECS",
        ECR: "ECR",
        KubernetesEKS: "Kubernetes (EKS)"
    },  
    database: {
        DB2: "DB2",
        Dynamo: "Dynamo",
        Heidi: "Heidi",
        Mongo: "Mongo",
        MySql: "MySql",
        RDS: "RDS",
        S3: "S3"
    },
    design: {
        CleanCode: "Clean Code",
        DDD: "DDD",
        OOP: "OOP",
        SOLID: "SOLID"
    },
    frontend: {
        AWSAmplify: "AWS Amplify",
        Bootstrap: "Bootstrap",
        CSS: "CSS",
        Javascript: "Javascript",
        Node: "Node",
        React: "React",
        ReactNative: "React-Native",
        SCSS: "SCSS"
    },
    genai: {
        Bedrock: "Bedrock",
        LangChain: "LangChain",
        LlamaIndex: "Llama Index",
        LLMPrinciples: "LLM Principles",
        PromptEngineering: "Prompt Engineering"
    },
    governance: {
        AWSOrganizations: "AWS Organizations",
        Cloudtrail: "Cloudtrail",
        Cloudwatch: "Cloudwatch",
        SystemsManager: "Systems Manager"
    },
    iac: {
        CDK: "CDK",
        Serverless: "Serverless",
        Terraform: "Terraform"
    },
    machineLearning: {
        Q: "Q",
        Rekognition: "Rekognition",
        SageMaker: "SageMaker",
        Textract: "Textract"
    },
    migration: {
        DataSync: "DataSync",
        AWSTransferFamily: "AWS Transfer Family"
    },
    networkingAndContentDelivery: {
        APIGateway: "API Gateway",
        CloudFront: "CloudFront",
        Route53: "Route 53",
        VPCSubnetCIDR: "VPC/Subnet/CIDR"
    },
    os: {
        Windows: "Windows",
        Unix: "Unix"
    },
    securityIdentityAndCompliance: {
        CertificateManager: "Certificate Manager",
        Cognito: "Cognito",
        IAM: "IAM",
        SecretsManager: "Secrets Manager",
        WAF: "WAF"
    },
    streaming: {
        KinesisVideoStreams: "Kinesis Video Streams"
    }
};

export const globalSkills: GlobalSkills = {
    architecturalDesigns: {
      Analytics: "Analytics",
      APIFirstDesign: "API-First Design",
      Containers: "Containers",
      CrossPlatformMobile: "Cross-Platform Mobile",
      DataMigration: "Data Migration",
      DependencyInjection: "Dependency Injection",
      EventDriven: "Event Driven",
      HADisasterRecovery: "High Availability - Disaster Recovery",
      Microservices: "Microservices",
      Multitenant: "Multi-tenant",
      Serverless: "Serverless",
      ServiceOrientedArchitecture: "Service-Oriented Architecture"
    },
    languages: {
      BashShell: "Bash/Shell",
      EurekaZuul: "Eureka/Zuul",
      Fortran: "Fortran",
      Java: "Java",
      NET: ".NET",
      PHP: "PHP",
      Python: "Python",
      SQL: "SQL",
      Spring: "Spring"
    }
  };

  export const softSkills: SoftSkills = {
    communication: {
      Verbal: "Verbal",
      Written: "Written",
      Presentation: "Presentation"
    },
    leadership: {
      Mentoring: "Mentoring",
      TeamLead: "Team Lead",
      Intentional: "Intentional"
    },
    programManagement: {
      ArchitecturalProjectManagement: "Architectural Project Management",
      RequirementsAnalysis: "Requirements Analysis",
      SCRUMAgileCeremonies: "SCRUM/Agile ceremonies"
    }
  };

export const jobs: Jobs = {
    slalomBuild: [
        {
            company: "Slalom Build",
            title: "Software Architect",
            startYear:"2021",
            startDate: "04/21",
            endDate: "04/23",
            details: [
                "<b>BRAD Project</b>: Innovated, architected, and led internal Slalom project BRAD (Better Reliable Auto-validation Device) that automated office parking validation.  Multiple peer-based workflows using different languages yielded <u>containerized architecture</u> separated by APIs.  which reduced office staff/leadership context switching and work-day interruptions. Reduced parking ticket automation from minutes to seconds.",
                "<b>Agrible Project</b>: Led cross-functional team to deliver the architecture for <u>cross-platform mobile</u> applications using Expo and React Native that provides real-time data on field-specific spraying conditions for farmers. Features included monitoring wind speed, rainfall, and temperature inversion potential.",
                "<b>Macy’s Project</b>: Automated physical inventory audits reports (leveraging Java and MySql), to reduce annual tax on staff needed to recreate audit reports.  Saves an average of 1 month for 2 peers annually.",
                "<b>Awards</b>: Leader and solution architect of the winning team in an internal ‘Slalomocity’ Architectural Challenge, which delivered an initial architecture, backlog, design, and cost estimation for an MVP solution."
            ],
            newPatterns: [globalSkills.architecturalDesigns.APIFirstDesign,
                globalSkills.architecturalDesigns.Containers,
                globalSkills.architecturalDesigns.CrossPlatformMobile,
                globalSkills.architecturalDesigns.EventDriven,
                globalSkills.architecturalDesigns.HADisasterRecovery
            ],  
            newSkills: [globalSkills.languages.Java,
                globalSkills.languages.Spring,
                globalSkills.languages.SQL,
                hardSkills.applicationIntegration.EventBridge,  
                hardSkills.applicationIntegration.StepFunctions,
                hardSkills.cloud.AWS,
                hardSkills.cloud.Azure,
                hardSkills.cicd.Expo,
                hardSkills.containers.KubernetesEKS,
                hardSkills.database.MySql,
                hardSkills.design.DDD,
                hardSkills.design.SOLID,
                hardSkills.frontend.ReactNative,
                hardSkills.governance.AWSOrganizations,
                hardSkills.iac.CDK,
                hardSkills.networkingAndContentDelivery.VPCSubnetCIDR,
                hardSkills.securityIdentityAndCompliance.Cognito,
                hardSkills.securityIdentityAndCompliance.IAM,
                hardSkills.securityIdentityAndCompliance.WAF,
                hardSkills.streaming.KinesisVideoStreams
            ],
            certifications: [CertificationType.SAAssociate, CertificationType.DeveloperAssociate, CertificationType.SysOpsAssociate],
            site: "https://www.slalombuild.com/",
            icon: "skiing"
        },
        {
            company: "Slalom Build",
            title: "Senior Software Architect",
            startYear:"2023",
            startDate: "04/23",
            endDate: "Present",
            details: [
                "<b>Texas Education Exchange Project</b>: Led requirements gathering initiatives that allowed for the ability for me to design and implement a comprehensive architectural solution for a <u>data ingestion and analytics</u> dashboarding solution to give Texas Educators a way to understand TEA TAPR data.  Giving public education employees the ability to apply for right-sized scholarships for year-of school funding.",
                "<b>Comcast Project</b>: Led the development of a centralized syndication pipeline for media which utilized <u>event-driven</u> messaging designs in a <u>serverless</u> architecture.  Mentored peers across disciplines and team changes, which has built lasting relationships beyond the project.",
                "Excelled in engaging with Fortune 100 companies in pre-sales, discovery, and build phases of project delivery.  Coordinated project planning, requirements gathering, and timeline estimation processes. Presented final products to executive level clients in POC, sprint, sales cycles.",
                "Utilized AWS services and GenAI toolkits throughout the SDLC.  Integrated RAG, Chatbot, Avatar, and Prompt Engineered solutions using LlamaIndex, Langchain (contributed to GitHub), and other popular GenAI stack tools.  Exhibiting proficiency in design and implementation of the GenAI space.",
                "<b>Awards</b>: Recipient of the \"<b>Build A Better Tomorrow</b>\" award, chosen by peers internally, recognizing individuals who exemplify Slalom's core values and principles."
            ],
            newPatterns: [
                globalSkills.architecturalDesigns.EventDriven,
                globalSkills.architecturalDesigns.HADisasterRecovery,
                globalSkills.architecturalDesigns.Multitenant,
            ],
            newSkills: [globalSkills.languages.Python,
                hardSkills.database.RDS,
                hardSkills.frontend.SCSS,
                hardSkills.genai.Bedrock,
                hardSkills.genai.LangChain,
                hardSkills.genai.LlamaIndex,
                hardSkills.genai.LLMPrinciples,
                hardSkills.genai.PromptEngineering,
                hardSkills.machineLearning.Q,
                hardSkills.machineLearning.Rekognition,
                hardSkills.machineLearning.SageMaker,
                hardSkills.machineLearning.Textract,
                hardSkills.securityIdentityAndCompliance.Cognito,
            ],
            certifications: [CertificationType.SAPro, CertificationType.SecuritySpecialty],
            site: "https://www.slalombuild.com/",
            icon: "skiing"
        }
    ],
    cbord: [
        {
            company: "CBORD",
            title: "Senior Software Engineer",
            startYear:"2019",
            startDate: "04/19",
            endDate: "04/20",
            details: [
                "Led external team members daily on technical and domain-specific topics in support calls and new features designs.",
                "Initiated several projects and proof of concepts to solve legacy technical debt challenges.",
                "Developed React webpages for CSGold's web migration, which leveled-up the technical stack to the modern era."
            ],
            newSkills: [globalSkills.languages.PHP, 
                hardSkills.communication.REST, 
                hardSkills.frontend.Bootstrap,
                hardSkills.frontend.Node,
                hardSkills.frontend.React,
            ],
            certifications: [],
            site: "https://www.cbord.com/",
            icon: "university"
        },
        {
            company: "CBORD",
            title: "Advisory Software Engineer",
            startYear:"2020",
            startDate: "04/20",
            endDate: "04/21",
            details: [
                "Served as technical lead on the Platform Team.  Aided in the design and maintenance of the company's global-scale, <u>multi-tenant</u> Hub integration project, which provided customers license management and informative <u>analytic</u> dashboards (Quicksight).",
                "Integrated AWS services such as functions, storage, and authentication throughout CBORD.",
                "Introduced and harnessed <u>Infrastructure-as-Code</u> with Serverless and Terraform to reduce time-in-console infrastructure creation."
            ],
            newPatterns: [
                globalSkills.architecturalDesigns.Analytics,
                globalSkills.architecturalDesigns.DataMigration,
                globalSkills.architecturalDesigns.Serverless
            ],
            newSkills: [
                globalSkills.languages.Python,
                hardSkills.analysis.AWSAthena,
                hardSkills.analysis.AWSGlue,
                hardSkills.analysis.AWSLakeFormation,
                hardSkills.analysis.AWSQuickSight,
                hardSkills.cloud.AWS,
                hardSkills.compute.Lambda,
                hardSkills.compute.EC2,
                hardSkills.containers.ECS,
                hardSkills.containers.ECR,
                hardSkills.database.Dynamo,
                hardSkills.database.S3,
                hardSkills.frontend.AWSAmplify,
                hardSkills.applicationIntegration.SNS,
                hardSkills.applicationIntegration.SQS,
                hardSkills.governance.Cloudtrail,
                hardSkills.governance.Cloudwatch,
                hardSkills.iac.Serverless,
                hardSkills.iac.Terraform,
                hardSkills.migration.AWSTransferFamily,
                hardSkills.migration.DataSync,
                hardSkills.networkingAndContentDelivery.APIGateway,
                hardSkills.networkingAndContentDelivery.CloudFront,
                hardSkills.networkingAndContentDelivery.Route53,
                hardSkills.securityIdentityAndCompliance.Cognito,
                hardSkills.securityIdentityAndCompliance.IAM,
                hardSkills.securityIdentityAndCompliance.SecretsManager
                
            ],
            certifications: [CertificationType.CloudPractitioner],
            site: "https://www.cbord.com/",
            icon: "university"
        }
    ],
    lockheedMartin: [{
        company: "Lockheed Martin - Skunkworks",
        title: "Software Engineer III",
        startYear:"2018",
        startDate: "09/18",
        endDate: "04/19",
        details: [
            "Built an innovative plugin-based dependency injection library using native Java, laying the framework for the next generation of Communication Software Packages for military Jets.",
            "Refactored and heavily optimized the entire CI/CD pipeline via Jenkins/Groovy, in turn greatly accelerating time-to-delivery for the team.",
            "Initiated more thoughtful and focused SCRUM practices (retrospectives, code reviews, better planning)."
        ],
        newPatterns: [globalSkills.architecturalDesigns.DependencyInjection],
        newSkills: [hardSkills.cicd.Jenkins,
            hardSkills.cicd.SonarQube,
        ],
        certifications: [],
        site: "https://www.lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html",
        icon: "fighter-jet"
    }],
    pier1: [{
        company: "Pier 1 Imports",
        title: "Software Engineer II",
        startYear:"2016",
        startDate: "04/16",
        endDate: "09/18",
        details: [
            "Developed on-prem <u>micro-service</u> architecture using Eureka/Zuul and Spring Boot to provide reliable, tolerant, and extensible Supply Chain products.",
            "Adopted the open-source mindset by actively seeking and applying new opportunities to enhance the customer experience."
        ],
        newPatterns: [
            globalSkills.architecturalDesigns.Microservices,
            globalSkills.architecturalDesigns.ServiceOrientedArchitecture
        ],
        newSkills: [globalSkills.languages.NET,
            globalSkills.languages.EurekaZuul,
            globalSkills.languages.Spring,
            hardSkills.cicd.Git,
            hardSkills.cicd.Gitlab,
            hardSkills.cicd.Jenkins,
            hardSkills.communication.REST,
            hardSkills.database.MySql,
            hardSkills.database.Heidi,
            hardSkills.database.Mongo,
            hardSkills.design.DDD,
            hardSkills.design.SOLID,
            hardSkills.design.CleanCode,
            hardSkills.frontend.Angular,
            hardSkills.frontend.Javascript,
            hardSkills.frontend.Bootstrap,
            hardSkills.frontend.CSS
        ],
        certifications: [],
        site: "https://www.pier1.com/",
        icon: "store"
    }],
    bnsf: [{
        company: "BNSF Railway",
        title: "Software Developer II",
        startYear:"2012",
        startDate: "09/12",
        endDate: "04/16",
        details: [
            "Drove preventative mechanical derailment efforts.  Solution included WebSphere, Java, UNIX, and TIBCO platforms",
            "Implemented “Train Matching” software in charge of aggregating multiple real-time data feeds enabling the ability to identify real-time locations of train cars in the field.",
            "Resolved on-call production issues leading to several initiatives for system optimization and stability."            
        ],
        newSkills: [globalSkills.languages.Java, 
            globalSkills.languages.SQL,
            hardSkills.analysis.Tableau, 
            hardSkills.communication.SOAP,
            hardSkills.communication.RabbitMQ,
            hardSkills.communication.WebSphere,
            hardSkills.communication.JMS,
            hardSkills.database.DB2,
            hardSkills.design.OOP,
            hardSkills.os.Unix
        ],
        certifications: [],
        site: "https://www.bnsf.com/",
        icon: "train"
    }],
    rtl: [{
        company: "RTL Genomics",
        title: "Intern",
        startYear:"2011",
        startDate: "11/11",
        endDate: "03/12",
        details: [
            "Assist and create stream line automation of DNA analysis processes using C#, Python, and UNIX",
            "Ground up creation of a multithreaded Server-Client application using C#’s TCP API"
        ],
        newSkills: [hardSkills.cloud.AWS, 
            globalSkills.languages.Python, 
            globalSkills.languages.BashShell],
        certifications: [],
        site: "https://rtlgenomics.com/",
        icon: "dna"
    }],
    halliburton: [{
        company: "Halliburton",
        title: "Intern",
        startYear:"2011",
        startDate: "06/11",
        endDate: "08/11",
        details: [
            "Created in-house C# applications used to meta-manage data from a database",
            "Familiarized selfwith FORTRAN and VisualBasic and embedded DLLs into other languages"
        ],
        newSkills: [globalSkills.languages.PHP, 
            globalSkills.languages.Fortran],
        certifications: [],
        site: "https://www.halliburton.com/"
    }],
    motio: [{
        company: "Motio Inc",
        title: "Intern",
        startYear:"2010",
        startDate: "05/10",
        endDate: "08/10",
        details: [
            "Familiarized self with IBM Cognos BI environment while applying to it programming",
            "Programmed on MotioPI, a java - based product used to accompany the IBMCognos platform"
        ],
        newSkills: [globalSkills.languages.Java],
        certifications: [],
        site: "https://motio.com/"
    }
    ]
}

export const slalomInternalProjects: string[] = [
    "Hackathon – Design and implement a multi-step GenAI web-interactive based experience that simulated the transformation of customer products needs into stories. Vision: Create a rich, detailed backlog in JIRA from chatbot interactions, then attach pseudo code and unit tests to each story as a SDLC accelerator",
    "Prompt Engineering - User story to code designing - Proof of Concept project around the ability of GenAI to assist in the process of going from a user story, to a sequence diagram, then to code!",
    "GenAI Chatbot - Integration of Azure OpenAI with an open-source chat bot with customization for client facing POC",
    "Hospital Simulation - Utilized Convai (GenAI + Avatars) and Unity game engine to prove out the ability for simulation of a hospital for the potential for appointment optimization."
]

export const collegeProjects: string[] = [
    "Created website that displays the latest news on every NASDAQ Stock ticker using web-scraped data.",
    "Implementation of Support Vector Machine and creation of custom Kernel",
    "Constant experimentation with technologies to understand their place in the development ecosystem"]

export const isometricTileGenerationProject: HobbyProject =
    {
        title: '<a href="https://ethanabowen.github.io/isometric-terrain-generation-hosting/">Isometric Terrain Generator</a>',
        description: "Used a home brewed Perlin Noise algorithm, this project combines Isometric Tileset Art with my vision of dynamic Terrain Generation. There are preset worlds setup for exploration and play! <strong>Maximize for best experience.</strong> <a href=\"https://ethanabowen.github.io/isometric-terrain-generation-hosting/\">Try it out!</a>",
        lastPublishDate: "01/03/2021",
        credits:
            [
                {
                    title: "Unity Game Engine",
                    link: "https://unity.com/"
                },
                {
                    title: "Sebastian Lague's Procedural Landmass Generation series.",
                    link: "https://www.youtube.com/watch?v=wbpMiKiSKm8&list=PLFt_AvWsXl0eBW2EiBtl_sxmDtSgZBxB3"
                },
                {
                    title: "Tileset designs by Max Heyder Art",
                    link: "https://assetstore.unity.com/publishers/10686"
                }
            ],
        gitHubUrl: "https://github.com/ethanabowen/isometric-terrain-generation"
    }