import "./Skills.css";
import React, { useEffect } from "react";
import Badge from 'react-bootstrap/Badge';

const Skills: React.FC<{ skills: string[], isSkillsList?: boolean }> = ({ skills, isSkillsList = false}) => {
  const skillsTopLineRef = React.useRef<HTMLDivElement>(null);

  // Define the Intersection Observer callback function
  const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && skillsTopLineRef.current) {
        skillsTopLineRef.current.classList.add('animate'); // Add the 'animate' class when the element is in the viewport
        observer.unobserve(entry.target); // Stop observing once the animation is triggered
      }
    });
  };

  // Use useEffect hook to set up the Intersection Observer when the component mounts
  useEffect(() => {
    // Create a new Intersection Observer instance
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5 // Trigger when 50% of the element is visible
    });

    // Observe the skills element
    if (skillsTopLineRef.current) {
      observer.observe(skillsTopLineRef.current);
    }

    // Clean up function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs only once


  var joinedSkills = skills
    .sort((a, b) => (a > b ? 1 : -1)) // alpha sort
    .map(((skill: string) => {
      if(skill === undefined) {
        return;
      }

      return <Badge key={`skill_${skill}_${Math.random()}`}bg="secondary" className="badge">{skill}</Badge>
    }
  ))
  .filter((skill) => skill) // Remove undefined values
  

  return (
    <div className="skills-container" >
      <div className="skills-bar" ref={skillsTopLineRef}>New {isSkillsList ? 'Skills': 'Architecture Patterns'}</div>
      <div className="skills">
          {joinedSkills}
      </div>
    </div>
  );
}

export default Skills;